import React from 'react'
import ReactEcharts from 'echarts-for-react'
import { filter, map } from 'lodash-es'
import { formatProductPlacement, generateDataPoint } from '../utils'
import { DARKER_GREY, GREEN, WHINE_RED, PURPLE } from '../theme/colors'

const ListingChart = ({ data, categories, name }) => {
  const listings = ['shelf', 'behindRegister', 'onDemand', 'other']
  const dataPoints = map(listings, (key) =>{
    return generateDataPoint(data, 'productPlacement', key, formatProductPlacement)
  }
  )

  const getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      legend: {
        orient: 'vertical',
        icon: 'roundRect',
        itemWidth: 13,
        itemHeight: 13,
        x: 'right',
        data: listings.map((key) => formatProductPlacement(key)),
        textStyle: {
          color: ['#74788d'],
        },
      },
      color: [GREEN, PURPLE, DARKER_GREY, WHINE_RED],
      series: [
        {
          name: 'Produktplacering',
          type: 'pie',
          radius: '55%',
          center: ['50%', '65%'],
          data: dataPoints,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    }
  }

  return (
    <React.Fragment>
      <ReactEcharts style={{ height: '350px' }} option={getOption()} />
    </React.Fragment>
  )
}
export default ListingChart
