import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'

import getEnvConfig from './env.config'
import Auth from '@aws-amplify/auth'

const getAWSApolloClient = () => {
  // console.log(getEnvConfig().apolloUri)
  const client = new AWSAppSyncClient({
    url: getEnvConfig().apolloUri,
    disableOffline: true,

    region: 'eu-west-1',
    auth: {
      // type: 'API_KEY',
      // apiKey: 'da2-nexbp6fqkna43dz2dahehdroui',
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      // // type: 'AMAZON_COGNITO_USER_POOLS',
      jwtToken: async () =>
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      // jwtToken: async () => token, // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
    },
  })
  return client
}
export default getAWSApolloClient
// const WithProvider = () => (
//   <ApolloProvider client= { client } >
//   <Rehydrated>
//   < /Rehydrated>
//   < /ApolloProvider>)
