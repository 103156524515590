import React from 'react'
import ReactEcharts from 'echarts-for-react'
import { formatShelfAppearance } from '../utils'
import { filter, map } from 'lodash-es'
import { generateDataPoint } from '../utils'
import {
  DARKER_GREY,
  GREEN,
  WHINE_RED,
  PURPLE,
  LIGHTER_GREEN,
} from '../theme/colors'

const ShelfAppearanceChart = ({ data }) => {
  const plotData = map(
    ['asExpected', 'didNotExist', 'notRefilled', 'messy', 'campaignMissing'],
    (key) =>
      generateDataPoint(data, 'shelfAppearance', key, formatShelfAppearance)
  )
  const getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)',
      },
      legend: {
        orient: 'vertical',
        icon: 'roundRect',
        itemWidth: 13,
        itemHeight: 13,
        x: 'right',
        data: [
          'Som förväntat',
          'Fanns ej i hyllan',
          'Ej påfyllt',
          'Ostädat',
          'Kampanj fattades',
        ],
        textStyle: {
          color: ['#74788d'],
        },
      },
      color: [GREEN, LIGHTER_GREEN, PURPLE, DARKER_GREY, WHINE_RED],

      series: [
        {
          name: 'Hur ser hyllan ut',
          type: 'pie',
          radius: ['45%', '55%'],
          center: ['50%', '65%'],

          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: 'center',
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: '18',
                fontWeight: 'bold',
              },
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: plotData,
        },
      ],
    }
  }
  return (
    <React.Fragment>
      {/* {plotData.map((item) => item.value).filter((value) => value !== 0)
        .length > 0 ? ( */}
      <ReactEcharts style={{ height: '350px' }} option={getOption()} />
      {/* ) : (
        <Typography>Ingen data att visas än</Typography>
      )} */}
    </React.Fragment>
  )
}
export default ShelfAppearanceChart
