import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { filter, map } from 'lodash-es'
import { formatCompetitionChoice } from '../utils'

import {
  DARKER_GREY,
  LIGHTER_GREEN,
  GREEN,
  PURPLE,
  WHINE_RED,
} from '../theme/colors'
import { Typography } from '@material-ui/core'

const colors = [WHINE_RED, LIGHTER_GREEN, PURPLE, GREEN, DARKER_GREY]

const CompetitorChart = ({ data }) => {
  const competitors = [
    ...new Set(
      map(data, (item) => item.competitors && item.competitors).flat()
    ),
  ].reverse()

  const keys = [
    'betterProduct',
    'habit',
    'betterPrice',
    'notRecommendingCompetitor',
    'other',
  ]
  const series = map(keys, (key) => {
    const dataSeries = map(competitors, (competitor) => {
      return filter(data, (item) => {
        return (
          item.competitors &&
          item.competitionChoice &&
          item.competitors.includes(competitor) &&
          item.competitionChoice.includes(key)
        )
      }).length
    })
    return { name: formatCompetitionChoice(key), data: dataSeries }
  })
  const isEmpty = series.map((item) => item.data).flat().lenght === 0
  const options = {
    colors: colors,
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: '40%',
      },
    },
    grid: {
      borderColor: '#f8f8fa',
      row: {
        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },

    xaxis: {
      categories: competitors,
      labels: {
        formatter: function (val) {
          return val
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
        },
      },
    },
    fill: {
      opacity: 1,
    },

    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right',
      offsetX: 40,
      markers: {
        height: 13,
        width: 13,
        radius: 3,
      },
    },
  }

  return (
    <React.Fragment>
      {isEmpty ? (
        <div style={{ height: '300px' }}>
          <Typography>Ingen data att visa än</Typography>
        </div>
      ) : (
        <ReactApexChart
          options={options}
          series={series}
          type='bar'
          height='290'
        />
      )}
    </React.Fragment>
  )
}

export default CompetitorChart
