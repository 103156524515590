import React, { useState } from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import StyledKeyboardDatePicker from "./StyledKeyboardDatePicker";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import styled from "@emotion/styled";

const PaddedWrapper = styled.div`
  margin-right: 1rem;
`;
const TimePicker = ({ startDate, endDate, setStartDate, setEndDate }) => {
  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <PaddedWrapper>
        <StyledKeyboardDatePicker
          label={"Startdatum"}
          name={"startdatum"}
          value={startDate}
          onChange={(event) => {
            if (!isValidDate(event)) {
              setStartDate(null);
            } else {
              setStartDate(event);
            }
          }}
        />
      </PaddedWrapper>
      <div>
        <StyledKeyboardDatePicker
          label={"Slutdatum"}
          name={"slutdatum"}
          value={endDate}
          onChange={(event) => {
            if (!isValidDate(event)) {
              setEndDate(null);
            } else {
              setEndDate(event);
            }
          }}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default TimePicker;
