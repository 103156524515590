import Auth from '@aws-amplify/auth'
import { toLower } from 'lodash-es'

import getEnvConfig from '../env.config'

const awsConfig = getEnvConfig()

Auth.configure({
  userPoolId: awsConfig.userpoolId,
  // identityPoolId: awsConfig.identityPoolId,
  userPoolWebClientId: awsConfig.clientId,
  region: awsConfig.region,
})

export const postLogin = (email, password) =>
  Auth.signIn({ username: toLower(email), password })

export const logout = () => Auth.signOut()

//ON Force new password

export const postNewPassword = (user, email, newPassword) => {
  console.log('postNewPassword', user)
  return Auth.completeNewPassword(user, newPassword)
}

export const postChangePassword = (user, oldPassword, newPassword) =>
  Auth.changePassword(user, oldPassword, newPassword)

export const postSignUp = (email, password) =>
  Auth.signUp({ username: toLower(email), password })

export const getCurrentUser = async () =>
  Auth.currentAuthenticatedUser().catch(() => null)

export const getJwtToken = async () => {
  try {
    const session = await Auth.currentSession()
    return session.getIdToken().getJwtToken()
  } catch (e) {
    return ''
  }
}

export const forgotPassword = (email) =>
  Auth.forgotPassword(email).catch(() => null)

// Collect confirmation code and new password, then copy
export const forgotPasswordSubmit = (email, code, new_password) =>
  Auth.forgotPasswordSubmit(email, code, new_password)
    .then((data) => console.log(data))
    .catch((err) => console.log(err))
