import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

import "chartist/dist/scss/chartist.scss";

import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import StyledSelect from "../../components/StyledSelect";
import styled from "@emotion/styled";
import HomeIcon from "@material-ui/icons/Home";
import { Divider, CircularProgress } from "@material-ui/core";
import { useLazyQuery, useQuery } from "react-apollo";
import { getMeetings, getCustomer } from "../../graphql";
import { get } from "lodash-es";
import TimePicker from "../../components/TimePicker";
import {
  formatMeetingNr,
  formatCity,
  getCityValues,
  getPharmacyValues,
  formatPharmacy,
  formatBrandKnowledge,
} from "../../utils";
import LoadingPage from "../../components/LoadingPage";
import ListingChart from "../../components/ListingChart";
import CompetitorChart from "../../components/CompetitorChart";
import ShelfAppearanceChart from "../../components/ShelfAppearanceChart";
import ProductKnowledgeChart from "../../components/ProductKnowledgeChart";
import CompetitorReasonChart from "../../components/CompetitorReasonChart";
import PersonIcon from "@material-ui/icons/Person";
import { map, reduce } from "lodash-es";
import { googleSilverTheme } from "../../theme/google";
import { PURPLE, GREEN } from "../../theme/colors";

const TimeWrapper = styled.div`
  display: flex;
  width: 100%;
`;
// border: solid green;

const StyledHomeIcon = styled(HomeIcon)`
  && {
    width: 1.3em;
    height: 1.4em;
  }
`;
const StyledGroupIcon = styled(PersonIcon)`
  && {
    width: 1.3em;
    height: 1.4em;
  }
`;

const MapInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const MapInfoContent = styled.div`
  margin: 1rem;
  text-align: center;
`;
const MapKpi = styled.div`
  border-bottom: 2px solid ${GREEN};
  display: flex;
  justify-content: center;
  text-align: center;
`;
const KPIText = styled.h5`
  margin: 4px 8px;
`;
const KPINumber = styled.h2`
  margin-top: 2px;
  margin-bottom: 0.2rem;
`;

const SelectorWrapper = styled.div`
margin 1rem 0.5rem;
`;
const filterData = (item, meetingsFilter) => {
  const includesOrEmptyChoice = (values, itemValue) => {
    if (!itemValue) {
      return false;
    }
    return values.length === 0 || values.includes(itemValue.toString());
  };
  return (
    includesOrEmptyChoice(meetingsFilter.meetingNr, item.meetingNumber) &&
    includesOrEmptyChoice(meetingsFilter.product, item.productId) &&
    includesOrEmptyChoice(meetingsFilter.pharmacy, item.pharmacy) &&
    includesOrEmptyChoice(meetingsFilter.city, item.region)
  );
};

const Dashboard = (props) => {
  const [startTime, setStartTime] = useState(null);
  // const [symbolStartTime, setSymbolStartTime] = useState(null)
  const [endTime, setEndTime] = useState(new Date());
  const [data, setData] = useState([]);
  const [bufferData, setBufferData] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  const [products, setProducts] = useState([]);
  const [customerLogo, setCustomerLogo] = useState(null);

  const [customerName, setCustomerName] = useState("");
  const [currentToken, setNextToken] = useState(null);
  const {
    data: customer,
    loading,
    error,
  } = useQuery(getCustomer, {
    onCompleted: (data) => {
      const customerProducts = get(
        data,
        ["getCustomer", "items", 0, "productNames"],
        []
      );
      setProducts(customerProducts);
      const name = get(
        data,
        ["getCustomer", "items", 0, "customerName"],
        "No customer"
      );
      setCustomerName(name);
      const logo = get(data, ["getCustomer", "items", 0, "customerLogo"], null);
      setCustomerLogo(logo);
      const newState = { ...meetingsFilter };
      newState.product = customerProducts.length > 0 ? customerProducts[0] : "";
      setFilter(newState);
      // setStartTime()
    },
    onError: (e) => {
      //TODO REMOVE
      console.log(e);
    },
  });
  const [stillFetching, setStillFetching] = useState(true);
  const [loadingVisitor, setLoadingVisitor] = useState(true);

  const [meetingsFilter, setFilter] = useState({
    product: "",
    city: getCityValues(customerName),
    pharmacy: getPharmacyValues(customerName),
    meetingNr: ["1", "2", "3", "4"],
  });

  useEffect(() => {
    setFilter((filter) => ({
      ...filter,
      city: getCityValues(customerName),
      pharmacy: getPharmacyValues(customerName),
    }));
  }, [customerName]);
  const [fetchMeetings, { fetchMore }] = useLazyQuery(getMeetings, {
    onCompleted: (result) => {
      const items = get(result, ["getMeetings", "items"], []);
      const nextToken = get(result, ["getMeetings", "nextToken"], null);

      setBufferData((prev) => {
        const newBuffer = [...prev, ...items];
        if (nextToken) {
          setNextToken(nextToken);
        } else {
          setStillFetching(false);
          setLoadingVisitor(false);
          setData([...prev, ...items]);
          const filteredItems = newBuffer.filter((item) =>
            filterData(item, meetingsFilter)
          );
          setFilteredData(filteredItems);
        }
        return newBuffer;
      });

      // setSymbolStartTime(data[data.length - 1].meetingDate)
    },
    onError: (error) => {
      setLoadingVisitor(false);
    },
  });

  useEffect(() => {
    fetchMeetings({
      variables: {
        // pharmacy: 'apoteketHjartat',
        startTime:
          startTime && startTime.toISOString().slice(0, 11) + "00:00:00.002Z",
        endTime:
          endTime && endTime.toISOString().slice(0, 11) + "23:59:00.002Z", //.slice(0, 11) + '23:59:00.002Z',
        nextToken: currentToken,
      },
    });
  }, [fetchMeetings, startTime, endTime, currentToken]);

  const handleChange = (event, key) => {
    const newState = { ...meetingsFilter };
    newState[key] = event.target.value;

    setFilter(newState);
  };
  const [initialCenter, setInitialCenter] = useState({
    lat: 59.3293,
    lng: 18.0686,
  });
  useEffect(() => {
    const newState = data.filter((item) => filterData(item, meetingsFilter));
    setFilteredData(newState);
    const getInitialCenter = () => {
      if (meetingsFilter.city.includes("stockholm")) {
        return { lat: 59.3293, lng: 18.0686 };
      }
      if (meetingsFilter.city.includes("uppsala")) {
        //Note: THIS IS key word uppsala but its for Umeå (yes confusing)
        return { lat: 63.824, lng: 20.252 };
      }
      if (meetingsFilter.city.includes("skane")) {
        return { lat: 55.70584, lng: 13.19321 };
      }
      if (meetingsFilter.city.includes("goteborg")) {
        return { lat: 57.70716, lng: 11.96679 };
      }
      if (meetingsFilter.city.includes("syddanmark")) {
        return { lat: 55.276928, lng: 10.613332 };
      }
      if (meetingsFilter.city.includes("sjaelland")) {
        return { lat: 55.463252, lng: 11.721498 };
      }
      if (meetingsFilter.city.includes("midtjylland")) {
        return { lat: 56.302139, lng: 9.302777 };
      }
      if (meetingsFilter.city.includes("nordjylland")) {
        return { lat: 56.830742, lng: 9.493053 };
      }
      if (meetingsFilter.city.includes("hovestaden")) {
        return { lat: 55.675181, lng: 12.549326 };
      }
      if (meetingsFilter.city.includes("ostlandet")) {
        return { lat: 59.911491, lng: 10.757933 };
      }
      if (meetingsFilter.city.includes("sorlandet")) {
        return { lat: 58.48222, lng: 8.7825 };
      }
      if (meetingsFilter.city.includes("trondelag")) {
        return { lat: 63.446827, lng: 10.421906 };
      }
      if (meetingsFilter.city.includes("vestlandet")) {
        return { lat: 60.3880473, lng: 5.3318581 };
      } else {
        return { lat: 59.3293, lng: 18.0686 };
      }
    };
    setInitialCenter(getInitialCenter());
  }, [meetingsFilter, data]);

  if (loading || stillFetching) {
    return <LoadingPage />;
  }

  const handelSetStartTime = (value) => {
    setNextToken(null);
    setData([]);
    setBufferData([]);
    setFilteredData([]);
    setStartTime(value);
    setLoadingVisitor(true);

    // setStillFetching(true)
  };
  const handelSetEndTime = (value) => {
    setNextToken(null);
    setData([]);
    setBufferData([]);
    setFilteredData([]);
    setEndTime(value);
    setLoadingVisitor(true);
    // setStillFetching(true)
  };
  return (
    <React.Fragment>
      <div className="container-fluid">
        <Row>
          <Col md={12} xs={12}>
            <Card style={{ marginTop: "1.5rem" }}>
              <CardBody>
                <Row>
                  <Col md={3}>
                    <SelectorWrapper>
                      <TimeWrapper>
                        <TimePicker
                          // startDate={!startTime ? symbolStartTime : startTime}
                          startDate={startTime}
                          endDate={endTime}
                          setStartDate={handelSetStartTime}
                          setEndDate={handelSetEndTime}
                        />
                      </TimeWrapper>
                      <Divider orientation="vertical" flexItem />
                    </SelectorWrapper>
                  </Col>
                  <Col md={2}>
                    <SelectorWrapper>
                      <StyledSelect
                        single={true}
                        values={products}
                        label="Produkt"
                        name="produkt"
                        selected={meetingsFilter.product}
                        onChange={(event) => handleChange(event, "product")}
                      />
                    </SelectorWrapper>
                  </Col>
                  <Col md={2}>
                    <SelectorWrapper>
                      <StyledSelect
                        values={getCityValues(customerName)}
                        label="Region"
                        formatFunction={formatCity}
                        name="stad"
                        selected={meetingsFilter.city}
                        onChange={(event) => handleChange(event, "city")}
                      />
                    </SelectorWrapper>
                  </Col>
                  <Col md={2}>
                    <SelectorWrapper>
                      <StyledSelect
                        values={getPharmacyValues(customerName)}
                        label="Kedja"
                        name="kedja"
                        formatFunction={formatPharmacy}
                        selected={meetingsFilter.pharmacy}
                        onChange={(event) => handleChange(event, "pharmacy")}
                      />
                    </SelectorWrapper>
                  </Col>
                  <Col md={2}>
                    <SelectorWrapper>
                      <StyledSelect
                        values={["1", "2", "3", "4"]}
                        label="Möte"
                        name="mölte"
                        formatFunction={formatMeetingNr}
                        selected={meetingsFilter.meetingNr}
                        onChange={(event) => handleChange(event, "meetingNr")}
                      />
                    </SelectorWrapper>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl={2} md={2}>
            <Card style={{ height: "98.3%" }} className="bg-primary">
              <CardBody>
                <div className="text-center text-white py-4">
                  {/* <h4>{customerName.toUpperCase()}</h4> */}
                  {customerLogo && (
                    <div style={{ marginBottom: "2rem", overflow: "hidden" }}>
                      <img
                        style={{
                          height: "auto",
                          width: "-webkit-fill-available",
                        }}
                        src={customerLogo}
                        alt="customer logo"
                        height="64"
                      />
                    </div>
                  )}
                  <h5>{meetingsFilter.product.toUpperCase()}</h5>
                </div>
                <div className="text-center text-white py-4"></div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={7} md={7}>
            <Row>
              <Col>
                <Card style={{ height: "450px" }}>
                  <CardBody>
                    <h4 className="mt-0 header-title mb-4">
                      Varumärkeskännedom
                    </h4>
                    <div id="scatter-chart" className="e-chart">
                      {/* <Bubbel /> */}
                      <ProductKnowledgeChart
                        data={filteredData}
                        customerName={customerName}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row style={{ marginBottom: "2rem" }}>
                      <Col md={6}>
                        <div className="text-center">
                          <h4 className="mt-0 header-title mb-4">
                            Rekommenderas på apotek
                          </h4>
                          <CompetitorChart data={filteredData} />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="text-center">
                          <h4 className="mt-0 header-title mb-4">
                            Varför rekommenderas konkurrenter
                          </h4>

                          <CompetitorReasonChart data={filteredData} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="text-center">
                          <ListingChart data={filteredData} />
                          <h4 className="mt-0 header-title mb-4">
                            Produktplacering
                          </h4>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="text-center">
                          <ShelfAppearanceChart data={filteredData} />
                          <h4 className="mt-0 header-title mb-4">
                            Hur hyllan ser ut
                          </h4>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col
            xl={3}
            md={3}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <MapInfoWrapper>
                      <MapInfoContent>
                        <MapKpi>
                          {loadingVisitor ? (
                            <KPINumber>
                              <CircularProgress
                                size={34}
                                style={{ color: GREEN }}
                              />
                            </KPINumber>
                          ) : (
                            <KPINumber>{filteredData.length}</KPINumber>
                          )}
                          <StyledHomeIcon style={{ fill: PURPLE }} />
                        </MapKpi>
                        <h4
                          style={{
                            marginTop: "0.3rem",
                          }}
                          className="header-title mb-4"
                        >
                          Antal besök
                        </h4>
                      </MapInfoContent>
                      <MapInfoContent>
                        <MapKpi>
                          {loadingVisitor ? (
                            <KPINumber>
                              <CircularProgress
                                size={34}
                                style={{ color: GREEN }}
                              />
                            </KPINumber>
                          ) : (
                            <KPINumber>
                              {reduce(
                                filteredData,
                                (a, b) => {
                                  return a + b.numberOfParticipants;
                                },
                                0
                              )}
                            </KPINumber>
                          )}
                          <StyledGroupIcon style={{ fill: PURPLE }} />
                        </MapKpi>
                        <h4
                          className="header-title mb-4"
                          style={{ marginTop: "0.3rem" }}
                        >
                          Antal Personal
                        </h4>
                      </MapInfoContent>
                    </MapInfoWrapper>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <div
                      id="gmaps-markers"
                      className="gmaps"
                      style={{ position: "relative" }}
                    >
                      <Map
                        mapTypeControl={false}
                        streetViewControl={false}
                        mapTypeControlOptions={false}
                        mapTypeIds={["roadmap"]}
                        google={props.google}
                        styles={googleSilverTheme}
                        initialCenter={initialCenter}
                        center={initialCenter}
                        zoom={meetingsFilter.city.length > 1 ? 4 : 10}
                        // zoom={10}
                        style={{ width: "100%", height: "100%" }}
                      >
                        {filteredData
                          .map((item) => {
                            if (item.pharmacyLocation) {
                              const { lat, lon } = item.pharmacyLocation;
                              return { lat: lat, lng: lon };
                            }
                            return { lat: null, lng: null };
                          })
                          .map((postition, index) => (
                            <Marker
                              key={`${index}_marker`}
                              position={postition}
                            />
                          ))}
                      </Map>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row style={{ flex: 1, marginBottom: 24 }}>
              <Col>
                <Card style={{ height: "100%" }}>
                  <CardBody>
                    <h4 className="mt-0 header-title mb-4">Möteslogg</h4>
                    <ol className="activity-feed">
                      {map(
                        filteredData.sort((a, b) =>
                          a.meetingDate < b.meetingDate ? 1 : -1
                        ),
                        (data, index) => (
                          <li
                            key={`meeting_item_${index}`}
                            className="feed-item"
                          >
                            <div className="feed-item-list">
                              <span className="date">
                                {new Date(data.meetingDate).toLocaleDateString(
                                  "se-SE"
                                )}
                              </span>
                              <span className="info" style={{ color: GREEN }}>
                                {`${formatPharmacy(data.pharmacyName)}`}
                              </span>

                              <span className="info">
                                {formatBrandKnowledge(data.brandKnowledge)}
                              </span>
                              <span className="activity-text">
                                {data.meetingNotes}
                              </span>
                            </div>
                          </li>
                        )
                      )}
                    </ol>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

// export default Dashboard
const LoadingContainer = (props) => <div>Loading...</div>;

export default GoogleApiWrapper({
  apiKey: "AIzaSyBKeJ1dOGpPZgpdMUA2mTv4WmgZAxWQKLQ",
  LoadingContainer: LoadingContainer,
})(Dashboard);
