import gql from 'graphql-tag'

export const getMeetings = gql`
  query getMeetings(
    # $region: Region
    $pharmacy: Pharmacies
    $startTime: AWSDateTime
    $endTime: AWSDateTime
    $limit: Int
    $nextToken: String
  ) {
    getMeetings(
      #   region: $region
      pharmacy: $pharmacy
      startTime: $startTime
      endTime: $endTime
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        meetingId
        customerId
        productId
        ambassadorId
        productName
        customerName
        meetingNotes
        meetingNumber
        participants
        participantsEmail
        numberOfParticipants
        brandKnowledge
        competitors
        competitionChoice
        shelfAppearance
        region
        pharmacy
        pharmacyLocation {
          lat
          lon
        }
        pharmacyName
        meetingDate
        productPlacement
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const getCustomer = gql`
  query getCustomer {
    getCustomer {
      items {
        customerName
        productNames
        customerId
        customerLogo
        userEmail
        slackChannel
      }
    }
  }
`
