import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ApolloProvider } from 'react-apollo'
import { UserProvider } from './context'
import { createBrowserHistory } from 'history'

import getAWSApolloClient from './apollo.config'
import store from './store'
const client = getAWSApolloClient()
const history = createBrowserHistory()

const app = (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <UserProvider history={history}>
        <Router history={history}>
          <App />
        </Router>
      </UserProvider>
    </Provider>
  </ApolloProvider>
)

ReactDOM.render(app, document.getElementById('root'))
serviceWorker.unregister()
