import React from 'react'
import ReactEcharts from 'echarts-for-react'
import { map } from 'lodash-es'
import { generateDataPoint } from '../utils'
import {
  DARKER_GREY,
  GREEN,
  WHINE_RED,
  PURPLE,
  LIGHTER_WHINE_RED,
  LIGHTER_GREEN,
  DARKER_WHINE_RED,
  DARKER_GREEN,
} from '../theme/colors'
import { Typography } from '@material-ui/core'

const CompetitorChart = ({ data }) => {
  const competitors = [
    ...new Set(map(data, (item) => item.competitors).flat()),
  ]
  const plotData = map(competitors, (key) =>
    generateDataPoint(data, 'competitors', key)
  )

  const getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      legend: {
        // icon: 'roundRect',
        icon: 'roundRect',
        itemWidth: 13,
        itemHeight: 13,

        // 'path://M30.9,53.2C16.8,53.2,5.3,41.7,5.3,27.6S16.8,2,30.9,2C45,2,56.4,13.5,56.4,27.6S45,53.2,30.9,53.2z M30.9,3.5C17.6,3.5,6.8,14.4,6.8,27.6c0,13.3,10.8,24.1,24.101,24.1C44.2,51.7,55,40.9,55,27.6C54.9,14.4,44.1,3.5,30.9,3.5z M36.9,35.8c0,0.601-0.4,1-0.9,1h-1.3c-0.5,0-0.9-0.399-0.9-1V19.5c0-0.6,0.4-1,0.9-1H36c0.5,0,0.9,0.4,0.9,1V35.8z M27.8,35.8 c0,0.601-0.4,1-0.9,1h-1.3c-0.5,0-0.9-0.399-0.9-1V19.5c0-0.6,0.4-1,0.9-1H27c0.5,0,0.9,0.4,0.9,1L27.8,35.8L27.8,35.8z',
        // orient: 'vertical',
        orient: competitors.length > 5 ? 'horizontal' : 'vertical',

        // right: 0,
        x: 'right',
        data: competitors,
        textStyle: {
          color: ['#74788d'],
        },
      },
      // color: [GREEN, WHINE_RED, PURPLE, DARKER_GREY],
      color: [
        GREEN,
        WHINE_RED,
        PURPLE,
        DARKER_GREY,
        LIGHTER_GREEN,
        LIGHTER_WHINE_RED,
        DARKER_WHINE_RED,
        DARKER_GREEN,
      ],

      series: [
        {
          name: 'Produkt',
          type: 'pie',
          // radius: '30%',
          radius: '55%',

          center: ['50%', '65%'],
          data: plotData,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    }
  }

  return (
    <React.Fragment>
      {plotData.length > 0 ? (
        <ReactEcharts style={{ height: '350px' }} option={getOption()} />
      ) : (
        <div style={{ height: '300px' }}>
          <Typography>Ingen data att visa än</Typography>
        </div>
      )}
    </React.Fragment>
  )
}
export default CompetitorChart
